import { UserGuard } from './guards/user.guard';
import { LOCALE_ID, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import localePt from "@angular/common/locales/pt";
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { AdminGuard } from './guards/admin.guard';
import { LoginGuard } from './guards/login.guard';
import { StorageService } from './services/localsorage.service';
import { LoginService } from './services/login.service';
import { AuthInterceptor } from './services/auth.interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

registerLocaleData(localePt);

@NgModule({
  declarations: [],
  imports: [
    BrowserModule,
    CommonModule,
    RouterModule,
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 3500,
      positionClass: 'toast-bottom-left'
    }),
  ]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        LoginService,
        StorageService,
        LoginGuard,
        AdminGuard,
        UserGuard,
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: LOCALE_ID,useValue: "pt-BR"},
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi:true },
      ],
    };
  }
}
