
import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { LoginService } from '../services/login.service';


@Injectable()
export class UserGuard implements CanActivate, CanLoad {

    constructor(
        private loginService: LoginService,
        private router: Router
    ) { };

    canActivate(): boolean {
        const acesso = !this.loginService.isAdmin;
        if (acesso) {
            return this.loginService.isLoggedIn();
        }
        if (!acesso) {
            this.router.navigate(['/']);
        }
        return false
    };

    canLoad(): boolean {
        let acesso = !this.loginService.isAdmin;
        if (acesso) {
            return true;
        }
        if (!acesso) {
            this.router.navigate(['/']);
        }
        return false
    };
}
